import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const useActivitiesApi = () => {

  const {getAccessTokenSilently} = useAuth0();


  const getClient = async (accessToken) => {
    return axios.create({
      baseURL: process.env.REACT_APP_WABWEB_BACKEND_HOST,
      // timeout: 1000,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
  }

  const getActivities = async () => {
    const accessToken = await getAccessTokenSilently();
    const client = await getClient(accessToken);

    return client.get('/api/activities.json')
      .then((res) => res.data)
      .catch((error) => error)
  }


  const createActivity = async ({designId, projectId, payload, image}) => {
    const accessToken = await getAccessTokenSilently();
    const client = await getClient(accessToken);

    return client.post('/api/activities.json', {
        payload: payload,
        image: image,
        design_id: designId,
        project_id: projectId
    })
  }

  const destroyActivity = async (projectId) => {
    const accessToken = await getAccessTokenSilently();
    const client = await getClient(accessToken);

    return client.delete(`/api/activities/${projectId}.json`)
        .then((res) => console.log(res));
  }  


  return {getActivities, createActivity, destroyActivity}
}

export {
  useActivitiesApi
}