import { Button } from "@blueprintjs/core";

const TrashButton = ({store, element}) => (  
  <>
  <div style={{marginTop: '5px' }} className='bp4-navbar-divider'></div>
  <Button
    icon='trash'
    minimal
    onClick={() => store.deleteElements([element.id]) }
    />
  </>
)

export {TrashButton};