
export const localeAr = 
{
  // ** Navbar **
  "Back to Designs": "العودة للتصاميم",
  
  "subscribe_now": "اشترك الآن",
  "recurring_subscriptions": "الاشتراكات", 
  "one_time": "الدفع لمرة واحدة",
  "get_started": "ابدأ الآن",
  
  "log_in": "تسجيل الدخول",

  "download": "تصدير",
  
  // toolbar
  "style": "الثيم",
  "invert_design": "عكس التصميم",
  "reset": "إعادة تعيين",

  // image toolbar
  "Change Image": "تغيير",
  "Themes Colors": "ألوان ثيمات",
  "Invert": "عكس التصميم",
  "Create Theme": "حفظ الثيم",
  "Theme Colors": "ثيمات",
  "Remove": "حذف",
  "cancel": "إلغاء",
  

  // add element
  "text": "نص",
  "image": "صورة",
  "shape": "شكل",
  "qr_code": "رمز QR",

  // **Navbar**
  

  // translations
  "remove_watermark": "إزالة العلامة المائية",

    // qr 
    "generate_qr_TITLE": "رمز QR",
    "generate_qr_BODY": "أضف رمز QR لأي رابط",
    "generate_qr_PLACEHOLDER": "الرابط الالكتروني (مثال: https://www.example.com)",
    "generate_qr_BUTTON_LABEL": "إضافة",


    // Background
    "more_backgrounds": "المزيد من الخلفيات",

    "general": "عام",
    "my_projects": "مشاريعي السابقة",
    "settings": "الاعدادات",
    "logout": "تسجيل الخروج",


    // text background
    "textBackground": "خلفية النص",
    "backgroundCornerRadius": "الزوايا",
    "backgroundOpacity": "الشفافية",
    "backgroundPadding": "العرض"
  }