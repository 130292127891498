import colorOnBg from "../../shared/on_color";

const colorFrom = ({theme, idx, strategy, originalColor}) => {
  return {
    'Disabled': originalColor,
    'Fill': theme[idx],
    'Contrast': colorOnBg(theme[idx])
  }[strategy]
} 

export default colorFrom;