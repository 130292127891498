 import { useContext, useEffect, useState } from 'react';
import EditorNavbar from '../navbar/navbar';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap, DuplicatePage } from 'polotno';
import { SidePanel, ElementsSection } from 'polotno/side-panel';


import { Workspace } from 'polotno/canvas/workspace';


// Sections
import {TextSection, BackgroundSection, UploadSection, SizeSection, LayersSection } from 'polotno/side-panel';
import ThemeSection from '../sections/theme';
import ImageUploadSection from '../image_upload/image_upload';

// Zoom
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';

import { PageControls } from '../customer_app/page_controls';
import CustomToolbar from '../toolbar/toolbar';
import { addWatermark, removeWatermarks } from '../utils/store_utils/apply_watermark';
import { useAuth0 } from '@auth0/auth0-react';
import { isDesigner } from '../utils/auth0_utils';
import { WabwebContext } from '../contexts/wabweb_context';


const DesignerEditor = ({store}) => {

  const sections = useSections();
  const height = useHeight();
  const {user} = useAuth0();
  const {userAccount} = useContext(WabwebContext); // useraccount should also tell if user is designer

  useEffect(() => { 
    if(isDesigner(user)){
      store.setRole('admin');
    }

      store.history.ignore(() => {
        removeWatermarks(store);
        if(!isDesigner(user) && !userAccount.isSubscribed){
          addWatermark({store});
        }
      })
  }, []);


  return (
    <div 
      className={"wabweb-editor"}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black'
      }}
    >
      <div style={{ height: '100vh' }}>
      
      <EditorNavbar store={store}/>
      

      <div style={{ height: 'calc(100vh- 100px)' }}>
      <PolotnoContainer className="polotno-app-container">
      
      {sections.length > 0 && 
        <SidePanelWrap>
          <SidePanel store={store} sections={sections} defaultSection="schema"/>
        </SidePanelWrap>
      }

      <WorkspaceWrap style={{display: 'block'}}>
        <CustomToolbar store={store}/>
        <div dir='ltr' style={{ height: 'calc(100vh - 100px)' }}>
          <Workspace 
            store={store} 
            components={{DuplicatePage, PageControls}}/>
        </div>

        <ZoomButtons store={store}/>
      </WorkspaceWrap>
    </PolotnoContainer>
    </div>
    </div>

  </div>
)}


const useSections = () => {
  const customerSections = [UploadSection, ElementsSection, BackgroundSection, ThemeSection, ImageUploadSection];
  const designerSections = [...customerSections, LayersSection, SizeSection, TextSection];
  
  const {user} = useAuth0();
  if(isDesigner(user)){
    return designerSections
  } else {
    return designerSections
  }
}


const useHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};




export default DesignerEditor;