import { Button, Icon } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import {Hidden} from "react-grid-system";

const SubscribeNowButton = ({buttonProps}) => {
const {t} = useTranslation();

return (
  <Button minimal 
    id="subscribeNowButton"
    style={{color: 'white'}}
    {...buttonProps}>
    <Icon icon="crown" style={{color: 'gold'}}/> 
    <Hidden xs>
      &nbsp;
      <b>{t('subscribe_now')}</b>
    </Hidden>
  </Button>
)}

export { SubscribeNowButton };