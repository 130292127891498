import { Button, NavbarDivider } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import { useEffect, useReducer } from "react";



const alignmentFor = (text) => {
  if(text[text.length - 1] === "\u200F"){
    return 'alignment-right';
  } else {
    return 'alignment-left';
  }
}


const AlignmentButton = observer(({element}) => {


  const [alignment, toggleAlignment] = useReducer((state) => {
    if(state === 'alignment-left'){
      return 'alignment-right';
    } else {
      return 'alignment-left';
    }
  }, alignmentFor(element.text));


  useEffect(() => {
    let str = element.text;
    let changes = {};
    str = str.replaceAll("\u200F", "");

    if(alignment === 'alignment-left') {
      changes = {text: str}
      if(element.align !== 'center') { changes = {...changes, align: 'left'} }
    } else {
      str = str.replaceAll("\n", "\u200F\n");
      changes = {text: str + "\u200F"}
      if(element.align !== 'center') { changes = {...changes, align: 'right'} }
    }

    element.set(changes);
  }, [alignment]);




  return (
    <>
      <NavbarDivider style={{marginTop: '5px'}}/>


      <Button 
        icon={alignment} 
        minimal 
        onClick={toggleAlignment}/>
    </>
  )
});


export {AlignmentButton};









// const AlignmentButton = observer(({element}) => {
  
//   const [alignment, setAlignment] = useState(() => {
//     if(element.text[element.text.length - 1] == "\u200F"){

//     } else {
      
//     }
//   });

//   const dir = (element.text[element.text.length - 1] == "\u200F") ? 'alignment-right' : 'alignment-left';



//   const toggleDirection = () => {
//     if(dir == 'alignment-right'){
//       element.set({text: element.text.slice(0, -1)});
//     } else {
//       element.set({text: element.text + "\u200F"});
//     }
//   }
  
//   return(<Button icon={dir} minimal onClick={toggleDirection}></Button>);
// });


// export {AlignmentButton};