import useInput from '../hooks/use_input';
import { FormGroup, InputGroup } from '@blueprintjs/core';

const TextField = ({name, handler = () => {}, style, initialValue, id, placeholder}) => {


  const [inputProps, resetInput] = useInput(initialValue, handler);

  return(

    <FormGroup label={name}>
      <InputGroup 
        {...inputProps}
        dir='auto' 
        placeholder={placeholder}
      />
    </FormGroup>    
  )
}

export default TextField;