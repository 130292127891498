import { createStore } from 'polotno/model/store';

const initializeStore = () => {
  const store = createStore({
    key: 'nFA5H9elEytDyPyvKL7T', // to create your own API key please go here: https://polotno.com/cabinet
    showCredit: true
  });

  return store;
}

export {
  initializeStore
}