import { WabwebElement } from "../../shared/wabweb_element";
import colorOnBg from '../../shared/on_color';
import {getColors, urlToString} from 'polotno/utils/svg';



// const refreshTheme = (store, theme, ) => {
//   refreshBackground(store, state.currentTheme, state.backgroundStrategy, state.backgroundColorIdx, originalBackground);
//   refreshElements(store.activePage.children, state.currentTheme);
// }


const refreshElements = (elements = [], theme = []) => {
  elements.forEach((el) => {
    applyFill(el, theme);
    applyBorder(el, theme);
  })
}


const refreshBackground = (store, theme, strategy, colorIdx, originalBackground = 'black') => {
  const page = store.activePage;

  const _color = theme[colorIdx];
  let color;

  if(strategy === 'Disabled'){
    color = originalBackground;
  } else if (strategy === 'Fill') {
    color = _color;
  } else {
    color = colorOnBg(_color);
  }

  page.set({background: color});
}


const applyFill = (el, theme) => {
  const welement = new WabwebElement(el);
  
  const _color = theme[welement.fillColorIdx()];
  let color;


  if(welement.fillStrategy() === 'Disabled'){
    return;
  }
  
  if (welement.fillStrategy() === 'Fill'){
    color = _color;
  } else if (welement.fillStrategy() === 'Contrast') {
    color = colorOnBg(_color)
  }

  
  if(el.type === "text"){
    el.set({fill: color});
  } else if(el.type === "svg"){
    getSvgColors(el).then((colors) => {
      el.replaceColor(colors[0], color);
    })
  } else {
   el.set({fill: color})
  }
}


const applyBorder = (el, theme) => {
  const welement = new WabwebElement(el);

  const _color = theme[welement.borderColorIdx()];
  let color;


  if(welement.borderStrategy() === 'Disabled'){
    return;
  }
  
  if (welement.borderStrategy() === 'Fill'){
    color = _color;
  } else if (welement.borderStrategy() === 'Contrast') {
    color = colorOnBg(_color)
  }


  if(el.type === "text"){
    el.set({stroke: color});
  } else if(el.type === "svg"){
    getSvgColors(el).then((colors) => {
      el.replaceColor(colors[1], color);
      el.set({borderColor: color});
    })
  } else {
   el.set({borderColor: color})
  }
}    

const getSvgColors = async (element) => {
  const svgString = await urlToString(element.src);
  const colors = getColors(svgString);
  return colors;
} 



export {
  refreshElements,
  refreshBackground
};