import { SectionTab } from 'polotno/side-panel';
import FaShapes from '@meronex/icons/fa/FaShapes';
import { observer } from 'mobx-react-lite';

import Unsplash from './unsplash_component';

import { FormGroup, FileInput } from '@blueprintjs/core';
import { useImageInput } from '../hooks/use_file_input';

const tab = (props) => (
  <SectionTab name="Image Upload" {...props}>
    <FaShapes icon="new-text-box" />
  </SectionTab>
)




const ImageFileField = ({store}) => {
  const element = store.selectedElements[0];

  const handler = (value) => {
    console.log(value);
    element.set({src: value})
  }

  const imageInputProps = useImageInput(handler); 

  return(
    <FormGroup
    helperText={'Upload a compatible file (png, jpeg)'}
    label={'Upload Image'}>
    <FileInput 
      {...imageInputProps}/>
  </FormGroup>
  )
}



const panel = observer(({store}) => {
  
  return(
    <div style={{overflowY: 'scroll', height: '100%'}}>
        <h2>Change Image</h2>
        <ImageFileField store={store}/>
        <hr/>
        <h4>Search photos</h4>   
        <Unsplash store={store}/>   
      </div>
  )
});

const ImageUploadSection = {
  name: 'wabweb-upload',
  tab: tab,
  Panel: panel,
  visibleInList: false,
}

export default ImageUploadSection;
