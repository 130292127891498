import { useEffect, useState } from "react";
import { Toaster, Toast, Position  } from "@blueprintjs/core";

const useStatusMessages = () => {
  const [message, setMessage] = useState();
  
  let timeoutHandler;
  
  useEffect(() => {  
    
    // previously set timeouts should be cleared for every new message.
    timeoutHandler && clearTimeout(timeoutHandler);

    timeoutHandler = setTimeout(()=>{
      setMessage(null);
    }, 3000)
  }, [message])


  const NotificationBar = () => (
    <Toaster position={Position.BOTTOM}>
      {
        message && <Toast message={message}/> 
      }
    </Toaster>
  )


  return {message, setMessage, NotificationBar}
}


export default useStatusMessages;