import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Button } from '@blueprintjs/core';

import { ThemeContext } from "../contexts/theme_context";
import ColorField from '../fields/color_field';
import { useTranslation } from "react-i18next";

const BackgroundColorButton = observer(({store}) => {
  const {t} = useTranslation();

  const {currentTheme, backgroundColorIdx, backgroundStrategy}= useContext(ThemeContext);
  
  // const [backgroundColor, setBackgroundColor] = useState(getBackgroundColor());

  const handleChange = (value) => {
    store.activePage.set({background: value});
    // setBackgroundColor(value);
  }

  useEffect(() => {
    // setBackgroundColor(getBackgroundColor());
  }, [currentTheme, backgroundStrategy, backgroundColorIdx])


  return(
    <ColorField 
      value={store.activePage?.background || 'rgba(255,255,255,1)'} 
      handler={(handleChange)} 
      onStart={() => store.history.startTransaction()}
      onDone={() => store.history.endTransaction()}
      icon="color-fill"
    >
      <Button style={{width: '100%'}} 
        intent="primary" 
        text={t("more_backgrounds")}
        onClick={() => store.openSidePanel('background')}/>
    </ColorField>
  )
});

export {BackgroundColorButton}