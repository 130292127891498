import DesignerEditor from '../components/designer_editor';

import {initializeStore} from '../features/store_management';

import {WabwebContextProvider} from '../contexts/wabweb_context';
import { ThemeContextProvider } from '../contexts/theme_context';

import {setupFonts} from '../lib/fonts/fonts';
import { setupTranslation } from '../customer_app/setup_translation';
import { useEffect } from 'react';


setupFonts();

const WabwebEditor = () => {
  const store = initializeStore(); 
  window.store = store;

  setupTranslation();
  
  return (
  <WabwebContextProvider store={store}>
    <ThemeContextProvider store={store}>
      <DesignerEditor store={store}/>;
    </ThemeContextProvider>
  </WabwebContextProvider>
  )
}


export default WabwebEditor;



