import JSZip from 'jszip';


const exportJsonFile = (store, name = null) => {
  const jsonString = exportJson(store);
  const fileName = name || 'design'
  _download( jsonString ,`${fileName}.json`, 'json');
}

const exportImage = (store, fileName = Date.now()) => {
  store.saveAsImage({fileName});
}

const exportZip = async ({store, currentTheme, themes, setCurrentTheme}) => {

  const images = [];  
  
  let image;
  for (let i = 0; i < themes.length; i++) {
    setCurrentTheme(i);
    image = await store.toDataURL();
    images.push(_dataURItoBlob(image));
  }


  // Create and Export Zipfile
  const zip = new JSZip();
  zip.file('name.json', exportJson(store));
  
  images.forEach((image, index) => {
    zip.file(`${index}.png`, image);
  });

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    _download(content, 'somename.zip', 'application/zip');
  });
  
}

const exportJson = (store) => {
  const json = store.toJSON();
  return JSON.stringify(json)
}


const exportImageData = async (store) => {
  const data = await store.toDataURL();
  return data;
}

const _download = (content, fileName, contentType) => {
  var a = document.createElement("a");
  var file = new Blob([content], {type: contentType});
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}


const _dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], {type: mimeString});
}


export {
  exportJsonFile,
  exportZip,
  exportImage,
  exportJson,
  exportImageData
}
