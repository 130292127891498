import { useAuth0 } from '@auth0/auth0-react';
import { Spinner} from '@blueprintjs/core';
import { createContext, useEffect, useState } from 'react';

import { useDesign } from '../hooks/use_design';
import useStatusMessages from '../hooks/use_status_messages';



const WabwebContext = createContext();

const WabwebContextProvider = ({store, children}) => {
  
  const {designLoading, designError, resetDesign, originalDesign, userAccount, subscriptionPlans} = useDesign(store);
  const {isLoading} = useAuth0();
  const {message, setMessage, NotificationBar} = useStatusMessages();
  const [pageDir, setPageDir] = useState('ltr');
  
  
  
  useEffect(() => {
    const 
      searchParams = new URLSearchParams(window.location.search),
      lang = searchParams.get('lang');
    
    if(lang == 'ar'){
      setPageDir('rtl');
    }
  }, []);
  


  const api = { resetDesign, originalDesign, userAccount, subscriptionPlans, message, setMessage, dir: pageDir}
  
  if(designError) {
    return <DesignError/>
  } else if(designLoading || isLoading) {
    return <LoadingState/>
  } else {
    return (
      <WabwebContext.Provider value={api}>
        <NotificationBar/>
        {children}
      </WabwebContext.Provider>
    )
  } 
}


const DesignError = () => (
  <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100vh'}}>
    <h4>Error: Can't load desgin</h4>
  </div>
)

const LoadingState = () => (
  <Spinner style={{height: '100vh'}}/>
)

export {WabwebContext, WabwebContextProvider};
