import { useContext, useEffect, useState } from 'react';

import {Button, Menu, MenuItem, MenuDivider, Icon, FileInput, Dialog} from '@blueprintjs/core';
import { Popover2 } from "@blueprintjs/popover2";        

import { ThemeContext } from '../contexts/theme_context';
import { clearCanvas } from '../utils/store_utils/clear';
import { exportImage, exportZip } from '../utils/store_utils/export';
import { importJson } from '../utils/store_utils/import';
import { useFileInput } from '../hooks/use_file_input';





const FileButton = ({store}) => {
  const {themes, currentTheme, setCurrentTheme} = useContext(ThemeContext);


  const [isOpen, setIsOpen] = useState(false);

  const {mode, setAdmin, setCustomer} = useMode({store});
  
  const importHandler = (jsonString) => {importJson(store, jsonString)};
  const exportHandler = (e) => exportZip({store, themes, currentTheme, setCurrentTheme});


  const fileInputProps = useFileInput(importHandler);


  return(
    <>
    <Popover2
    interactionKind="click"
    placement="bottom"
    content={
      <>
      <Menu>
          <MenuItem icon='clean' text='Clear Canvas' onClick={() => clearCanvas(store)}/>
          <MenuDivider title={'Import'}/>
          <MenuItem text='Import Design' icon='import' onClick={()=> setIsOpen(true)}/>
          <MenuDivider title={'Export'}/>
          <MenuItem icon='media' text={'Export Image'} onClick={() => exportImage(store)}/>
          <MenuItem icon='compressed' text='Export Zip' onClick={exportHandler}/>
          <MenuDivider title={'Mode'}/>
          {
            mode === 'customer' && 
            <MenuItem icon='user' text={'Admin Mode'} onClick={() => setAdmin()}/>
          }
          {
            mode === 'admin' && 
            <MenuItem icon='user' text={'Customer Mode'} onClick={() => setCustomer()}/>
          }
      </Menu>
      </>
    }>

      <Button      
      minimal>
        <Icon icon='document' style={{color: 'white'}}/>
      </Button>
    </Popover2>
    
    <Dialog 
      isOpen={isOpen} 
      canOutsideClickClose 
      canEscapeKeyClose
      onClose={() => setIsOpen(false) }
      >
      <div style={{padding: '10px'}}>
        <h3>Upload Json file</h3>
        <FileInput 
          {...fileInputProps}
          style={{width: '80%'}}
          text='Import Design'/>
        </div>
    </Dialog>
    </>
)}


const useMode = ({store}) => {

  const [mode, setMode] = useState('admin');

  useEffect(()=>{
    store.setRole(mode);
  }, [mode]);

  return {
    mode,
    setAdmin: () => setMode('admin'),
    setCustomer: () => setMode('customer')
  }
}




export {FileButton};