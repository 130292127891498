import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import { WabwebContext } from '../contexts/wabweb_context';
import invertTheme from '../utils/store_utils/invert';


const openTheme = (store) => {
  if(store.openedSidePanel !== 'theme'){
    store.openSidePanel('theme')
  } else {
    store.openSidePanel('')
  } 
}

const useResetDesign = () => {
  const {resetDesign} = useContext(WabwebContext);

  return({
    reset: () => {
      if(window.confirm("Do you want to discard your customizations?")){
        resetDesign();
      }
    }
  })
}

const StyleButton = ({store}) => {
  const {t} = useTranslation();
  const {reset} = useResetDesign();

  return (
    <Popover2
      interactionKind="click"
      placement="bottom"
      content={
        <>
        <Menu>
            <MenuItem 
              text={t("style")}
              icon="color-fill" onClick={() => openTheme(store)}/>
            <MenuItem text={t("invert_design")} icon="arrows-horizontal" onClick={() => invertTheme(store)}/>
            <MenuDivider/>
            <MenuItem text={t("reset")} icon="trash" intent="none" onClick={reset}/>
        </Menu>
        </>
      }
    >
      <Button      
        icon="style" 
        minimal
        />
    </Popover2>
  )
}

export {StyleButton};