import { useState } from "react";


function getBase64(file, cb, type = 'file') {
  let reader = new FileReader();

  if(type === 'image'){
    reader.readAsDataURL(file);
  }
  else{ 
    reader.readAsText(file);
  }

  reader.onload = function () {
      cb(reader.result)
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}


const useFileInput = (handler = () => {}, type = 'file') => {
  const [name, setName] = useState('Choose File');

  const handleChange = (ref) => {
    let file = ref?.target?.files[0]
    setName(file.name);
    if(file){
      getBase64(file, (result) => { 
        handler(result);
      }, type);
    }
  }

  return {text: name, onChange: handleChange}
}

const useImageInput = (handler = () => {}, type = 'image') => {
  return useFileInput(handler, type)
}

export {
  useFileInput,
  useImageInput
}