import {createContext} from 'react';
import { useThemes } from '../theme/use_themes';

const ThemeContext = createContext();


const ThemeContextProvider = ({store, children}) => {
  

  const themeApi = useThemes(store);
  
  return (
    <ThemeContext.Provider value={themeApi}>
      {children}
    </ThemeContext.Provider>
  )
}

export {ThemeContext, ThemeContextProvider};




