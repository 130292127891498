import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { getLang } from './setup_translation';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Icon } from "@blueprintjs/core";
import { useSubscribeDialog } from '../hooks/use_subscribe_dialog';

export const PageControls = observer(({ store, page, xPadding, yPadding }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  // const hasManyPages = store.pages.length > 1;

  const direction = (getLang() === 'ar') ? {right: xPadding + "px"} : {left: xPadding + "px"};


  const {SubscribeDialog, dialogTriggerProps} = useSubscribeDialog();

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: yPadding - 35 + "px",
          color: '#a1a1a1',
          ...direction
        }}
      >
       {
        <>
            <Button dir='auto' 
              small
              {...dialogTriggerProps}
              style={{paddingInline: '15px'}} >
                <Icon icon="crown" 
                  style={{color: 'gold', marginBottom: '2px', stroke: "#F1CA30", strokeWidth: '1px' }}/> &nbsp;&nbsp;
                {t('remove_watermark')}
            </Button>


        </>
        }

        {/* {
          <>
            {hasManyPages && (
              <Button
                icon="trash"
                minimal
                onClick={() => {
                  store.deletePages([page.id]);
                }}
              ></Button>
            )}

            <Button
            icon="duplicate"
            minimal
            onClick={() => {
              page.clone();
            }}></Button>     
        </>
        } */}

      </div>

      <SubscribeDialog/>
    </>
  );
});
