import { useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/use_localstorage";
import { useDesignsApi } from "../lib/wabweb_api/designs";
import { useSearchParams } from "react-router-dom";


const useDesign = (store) => {
  const [originalDesign, setOriginalDesign] = useState(null);
  const [designError, setDesignError] = useState(null);
  const [designLoading, setDesignLoading] = useState(true);
  const [resData, setResData] = useState(null);
  
  const [_searchParams, setSearchParams] = useSearchParams();


  const 
    searchParams = new URLSearchParams(window.location.search),
    designId = searchParams.get('d'),
    projectId = searchParams.get('p'),
    locale = searchParams.get('lang');

  const {getDesign} = useDesignsApi();
  const {setItem, getItem} = useLocalStorage();
  
  
  const resetDesign = () => {
    setDesignLoading(true);
    setTimeout(() => setDesignLoading(false), 300);
    store.loadJSON(originalDesign, false);
  }

  
  useEffect(() => {
    (async () => {
      const data = await getDesign({designId, projectId, locale});
      setResData(data);
    })();
  }, []);

  useEffect(() => {
    if(!resData) return;
    console.log(resData);

    loadFromUrl(resData.design.payload_url).then((jsonData) => {
      setOriginalDesign(jsonData);
      setDesignLoading(false);
    });
  }, [resData])


  useEffect(() => {
    if(!originalDesign) return;

    if(projectId){
      store.loadJSON(originalDesign, false)

      _searchParams.delete('p');
      setSearchParams(_searchParams)
    } else if (getItem()){
      store.loadJSON(getItem(), false);
    } else {
      store.loadJSON(originalDesign, false);
    }
  }, [originalDesign]);



  // cache changes in store periodically.
  useEffect(() => {
    if(designLoading) return;

    // remove projectId and lang from url


    const cacheInterval = window.setInterval(()=>{
      setItem(store.toJSON());
    }, 5000);

    return (() => {
      window.clearInterval(cacheInterval);
    })    
  }, [designLoading]);


  if(designLoading) return {designLoading};

  return {
    designError,
    designLoading,
    userAccount: parseResponse(resData).userAccount,
    subscriptionPlans: parseResponse(resData).subscriptionPlans,
    originalDesign,
    resetDesign
  }

}




const loadFromUrl = (url) => {
  return fetch(url, {
    mode: 'cors',
    headers: {
      Origin: window.location.origin
    }
  })
  .then ((response) => response.json())
}








// const useDesignz = (store) => {
//   const 
//     [isLoading, setIsLoading] = useState(true),
//     [originalDesign, setOriginalDesign] = useState(null),
//     {getItem, setItem} = useLocalStorage(localStoragePath());
  
//   const 
//     searchParams = new URLSearchParams(window.location.search),
//     designId = searchParams.get('d'),
//     projectId = searchParams.get('p'),
//     locale = searchParams.get('lang');

//   const {loading, data, error, executeGetDesign} = useGetDesign({designId, projectId, locale});


  



//   useEffect(() => {
//     if(loading || error) return;
//     (async () => {
//       await executeGetDesign();
//       loadLatestDesign();
//       setIsLoading(false);
//     })();
//   }, [loading]);


//   useEffect(() => {
//     if(loading || error) return;

//     const cacheInterval = window.setInterval(()=>{
//       setItem(store.toJSON());
//     }, 5000);

//     return (() => {
//       window.clearInterval(cacheInterval);
//     })    
//   }, [loading]);
      

//   const resetDesign = () => {
//     setIsLoading(true);
//     setTimeout(() => setIsLoading(false), 300);
//     store.loadJSON(originalDesign, false);
//   }


//   // If project is present, load it. Otherwise, check cache
//   const loadLatestDesign = () => {
//     let storeData;
//     const cached = getItem();


//     const loadFromUrl = (url) => {
//       return fetch(url, {
//         mode: 'cors',
//         headers: {
//           Origin: window.location.origin
//         }
//       })
//       .then ((response) => response.json())
//     }


//     if(data.project){
//       loadFromUrl(data.project.payload_url).then((jsonData) => {
//         setOriginalDesign(jsonData);
//         store.loadJSON(jsonData, false);
//       })
//     } else {
//       loadFromUrl(data.design.payload_url).then((jsonData) => {
//         setOriginalDesign(jsonData);
//         if(cached) {
//           store.loadJSON(cached, false);
//         } else {
//           store.loadJSON(jsonData, false);
//         }
//       })
//     }
//   }






//   if(loading) return {designLoading: isLoading};
//   if (error) return {designError: error};

  
//   const {userAccount, subscriptionPlans} = parseResponse(data);


//   if(!originalDesign) return {designLoading: true};


//   return {
//     designError: error,
//     designLoading: isLoading,
//     userAccount, 
//     subscriptionPlans,
//     originalDesign,
//     resetDesign,
//   }
// }




// const useDesign = (store) => {
//   const 
//     [isLoading, setIsLoading] = useState(true),
//     [originalDesign, setOriginalDesign] = useState(null),
//     {getItem, setItem} = useLocalStorage(localStoragePath());
  
//   const 
//     searchParams = new URLSearchParams(window.location.search),
//     designId = searchParams.get('d'),
//     projectId = searchParams.get('p'),
//     locale = searchParams.get('lang');

//   const {loading, data, error, executeGetDesign} = useGetDesign({designId, projectId, locale});
  



//   useEffect(() => {
//     if(loading || error) return;
//     (async () => {
//       await executeGetDesign();
//       loadLatestDesign();
//       setIsLoading(false);
//     })();
//   }, [loading]);


//   useEffect(() => {
//     if(loading || error) return;

//     const cacheInterval = window.setInterval(()=>{
//       setItem(store.toJSON());
//     }, 5000);

//     return (() => {
//       window.clearInterval(cacheInterval);
//     })    
//   }, [loading]);
      

//   const resetDesign = () => {
//     setIsLoading(true);
//     setTimeout(() => setIsLoading(false), 300);
//     store.loadJSON(originalDesign, false);
//   }


//   // If project is present, load it. Otherwise, check cache
//   const loadLatestDesign = () => {
//     let storeData;
//     const cached = getItem();


//     const loadFromUrl = (url) => {
//       return fetch(url, {
//         mode: 'cors',
//         headers: {
//           Origin: window.location.origin
//         }
//       })
//       .then ((response) => response.json())
//     }


//     if(data.project){
//       loadFromUrl(data.project.payload_url).then((jsonData) => {
//         setOriginalDesign(jsonData);
//         store.loadJSON(jsonData, false);
//       })
//     } else {
//       loadFromUrl(data.design.payload_url).then((jsonData) => {
//         setOriginalDesign(jsonData);
//         if(cached) {
//           store.loadJSON(cached, false);
//         } else {
//           store.loadJSON(jsonData, false);
//         }
//       })
//     }
//   }






//   if(loading) return {designLoading: isLoading};
//   if (error) return {designError: error};

  
//   const {userAccount, subscriptionPlans} = parseResponse(data);


//   if(!originalDesign) return {designLoading: true};


//   return {
//     designError: error,
//     designLoading: isLoading,
//     userAccount, 
//     subscriptionPlans,
//     originalDesign,
//     resetDesign,
//   }
// }


const parseResponse = (resData) => {
  console.log(resData);

  return {
    userAccount: {
      isSubscribed: resData.user.subscription_active,
      updateUrl: resData.user.update_url,
      cancelUrl: resData.user.cancel_url
    },

    subscriptionPlans: resData.subscription_plans.map((plan) => ({
      name: plan.name,
      description: plan.description,
      planId: plan.plan_id,
      planType: plan.plan_type
    }))
  }
}



const localStoragePath = () => {
  const location = window.location.href;
  let params = new URLSearchParams(location.search);
  params.delete('lang');
  params.delete('p');
  const key = location.protocol + '//' + location.host + location.pathname + '?' + params.toString();
  return key;
}






export {useDesign};
