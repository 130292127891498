// Polotno
import { setTranslations } from 'polotno/config';

// I18n
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation Files
import {localeAr} from '../translations/ar/locale';
import {localeEn} from '../translations/en/locale';

import polotno_translations from '../translations/polotno.json'


// console.log(getTranslations());






const getLang = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get('lang')

  return lang;
}


const setupPolotnoTranslation = () => {
  const supportedLocales = ['ar']

  if(supportedLocales.includes(getLang())){
    setTranslations(polotno_translations[getLang()])
  }
}

const setupI18n = () => {
  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ar: { translation: localeAr},
      en: {translation: localeEn}
    },
    lng: getLang(), // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
}



const setupTranslation = () => {
  setupPolotnoTranslation();
  setupI18n();
}


export {
  setupTranslation,
  getLang
};