import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Checkbox, Divider, Menu, MenuDivider, MenuItem, Tabs, Tab} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WabwebContext } from "../contexts/wabweb_context";
import { useActivitiesApi } from "../lib/wabweb_api/activities";
import {Hidden} from "react-grid-system";


import { exportImage, exportImageData, exportJson } from '../utils/store_utils/export';




const DownloadButton = ({store}) => {
  
  const [tab, setTab] = useState('download');

  const {setMessage} = useContext(WabwebContext);

  const {t} = useTranslation();

  const { isAuthenticated } = useAuth0();
  const {createActivity} = useActivitiesApi();



  const handleClick = () => {
    const designId = new URLSearchParams(window.location.search).get('d');
    const jsonString = exportJson(store);

    setMessage('Exporting...');


    exportImageData(store).then((imageData) => {
      if (!isAuthenticated) return true;
      setMessage('Saving to your projects...');

      createActivity({
        payload: jsonString,
        image: imageData,
        designId //,
        //projectId
      })
    });

    exportImage(store, Date.now());
  }
    
  const handleTabChange = (id) => {
    setTab(id);
  }

  return (

    <Popover2
      placement="bottom"
      content={
        <div
          style={{minWidth: '300px', padding: '10px'}}
        >

          <Tabs className="no-outline" onChange={handleTabChange} style={{border: 0}}>
            <Tab className="no-outline" id='download'>Download</Tab>
            <Tab className="no-outline" id='download_free'>Download *Free*</Tab>
          </Tabs>

          <style>
            {
              `
                .no-outline:focus{
                  outline: none;
                  border: none;
                }
              `
            }
          </style>

          <Divider/>

          {
            (tab === 'download_free') && <DownloadFreeTab store={store} handleDownload={handleClick}/>
          }


        </div>
      }
      >
      <Button
        text={
            t("Share")
        }
        icon={"export"}
        minimal
        // onClick={handleClick}  
        style={{color: '#393e46', backgroundColor: 'white', fontWeight: 'bold', fontSize: '15px'}}
        />
    </Popover2>
  )
}




const DownloadFreeTab = ({store, handleDownload}) => (
  <div 
  style={{ borderRadius: '4px', 
      backgroundColor: ''}}>
    <b>Community Option (Free)</b>
    <p>
      <i style={{color: '#64748b'}}>Your design may be selected and promoted to other site visits</i>
    </p>
    
    <Button intent={'success'} onClick={handleDownload}> Free Download </Button>
  </div>  
)


const DownloadTab = ({store}) => {

}



export {DownloadButton};