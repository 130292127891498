import { Button } from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';
import { useQrDialog } from './use_qr_dialog';
import FaQrcode from '@meronex/icons/fa/FaQrcode';


const QrButton = ({store, element, elements}) => {
  const {t} = useTranslation();
  
  const {QrDialog, openQrDialog} = useQrDialog({store, element});


  if(element.name === 'qr'){
    return(
      <>
        <div style={{marginTop: '5px' }} className='bp4-navbar-divider'></div>
        <Button
          onClick={() => openQrDialog()}
          className='button'
          intent='primary'
          icon={<FaQrcode/>}
        /> 
        <QrDialog/>
      </>
    )
  }else {
    return <></>;
  }
}


export {QrButton};
