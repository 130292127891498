import { useAuth0 } from "@auth0/auth0-react"


const PrivateRoute = ({component}) => {
  const {isLoading, isAuthenticated} = useAuth0();

  if(isLoading) {
    return <>Authenticating...</>;
  }

  if(!isAuthenticated) {
    return <>login first</>
  }

  return (
    <>
      {component}
    </>
  )
}


export {PrivateRoute};