

import {Navbar, Button, Alignment, InputGroup, Divider} from '@blueprintjs/core';

import { useAuth0 } from "@auth0/auth0-react";

import { isDesigner } from '../utils/auth0_utils';

import { useSubscribeDialog } from '../hooks/use_subscribe_dialog';



import { UserButton } from './user_button';
import { FileButton } from './file_button';
import { DownloadButton } from './download_button'
import { SubscribeNowButton } from './subscribe_now_button';
import { BackToDesignsButton } from './back_to_designs_button';
import { LoginButton } from './login_button';
import { useContext } from 'react';
import { WabwebContext } from '../contexts/wabweb_context';


const EditorNavbar = ({store}) => {
  const { user, isAuthenticated, dir } = useAuth0();
  
  const {userAccount} = useContext(WabwebContext);


  const {SubscribeDialog, dialogTriggerProps} = useSubscribeDialog();

  return (

    <Navbar 
      dir={dir}
      style={{
        background: 'linear-gradient(90deg, hsla(235, 90%, 70%, 1) 0%, hsla(235, 50%, 50%, 1) 50%)',
        display: 'flex', overflowX: 'scroll'}}>

            <Navbar.Group style={{flexGrow: '1'}}>
              <BackToDesignsButton/>
            </Navbar.Group>
        <Navbar.Group>
          <Navbar.Divider />
          { 
            !userAccount.isSubscribed && !isDesigner(user) &&
            <>
              <SubscribeNowButton buttonProps={{...dialogTriggerProps}}/>
              <Navbar.Divider />
            </>
          } 
          
          {
            (isDesigner(user)) && 
            <>
              <FileButton store={store}/>
              <Navbar.Divider/>
            </>
          }
          
          {
            isAuthenticated && 
            <>
              <UserButton store={store} dialogTriggerProps={dialogTriggerProps}/>
              <Navbar.Divider />
            </>
          }

          {

            !isAuthenticated && 
            <>
              <LoginButton/>
              <Navbar.Divider />
            </>
          }

          {
            <DownloadButton store={store}/>
          }
          <SubscribeDialog/>
        </Navbar.Group>
    </Navbar>
  )
}

export default EditorNavbar;