import { useContext, useEffect, useReducer} from 'react';
import { refreshElements, refreshBackground } from '../utils/store_utils/refresh';
// import refreshBackground from '../utils/wabweb_utils/refresh_background';
import { WabwebContext } from '../contexts/wabweb_context';
import colorFrom from '../utils/wabweb_utils/color_from';
import colorOptions from '../helpers/color_options';


const useOriginalBackground = () => {
  const {originalDesign} = useContext(WabwebContext);
  return originalDesign.pages[0]?.background || 'rgba(255,255,255,1)';
}

const useThemes = (store) => {
  const originalBackground = useOriginalBackground();

  // remove old 

  const initialState = {
    themes: [],
    currentTheme: [],
    numberOfColors: 2,
    backgroundStrategy: 'Disabled',
    backgroundColorIdx: 0,
    ...store.pages[0]?.custom
  }


  const [state, dispatch] = useReducer(themesReducer, initialState);
  const colorStrategies = ['Disabled', 'Contrast', 'Fill'];





  const refreshTheme = () => {
    refreshBackground(store, state.currentTheme, state.backgroundStrategy, state.backgroundColorIdx, originalBackground);
    
    store.pages.forEach((page) => {
      refreshElements(page.children, state.currentTheme);
    })
  }

  const refreshElement = (element) => {
    refreshElements([element], state.currentTheme);
  }

  const getBackgroundColor = () => {
    let _color = state.currentTheme[state.backgroundColorIdx];

    const color = colorFrom({theme: state.currentTheme, 
        idx: state.backgroundColorIdx, 
        strategy: state.backgroundStrategy,
        originalColor: originalBackground })

    return color
  }


  // sync with custom field
  useEffect(() => {
    if(JSON.stringify(state) !== JSON.stringify(initialState)){
      store.pages[0].set({custom: {
        ...store.pages[0].custom, 
        ...state
      }})

      refreshTheme();
    }
  }, [state]);




  return {
    themes: state.themes,
    currentTheme: state.currentTheme,
    numberOfColors: state.numberOfColors,
    colorStrategies, 
    colorOptions: colorOptions(state.currentTheme), 
    
    addTheme: () => {dispatch({type: 'add-theme', payload: {theme: state.currentTheme}})},
    removeTheme: (idx) => dispatch({type: 'remove-theme', payload: {idx: idx}}),
    setCurrentTheme: (idx) => dispatch({type: 'select-theme', payload: {idx: idx}}),
    setBackgroundStrategy: (value) => {dispatch({type: 'set-background-strategy', payload: { value: value }})},
    setBackgroundColorIdx: (value) => {dispatch({type: 'set-background-color-idx', payload: { value: value }})},
    setColor: (color, idx) => dispatch({type: 'set-color', payload: {value: color, idx: idx}}),
    refreshTheme,
    refreshElement,
    setNumberOfColors: (number) => dispatch({type: 'set-number-of-colors', payload: {numberOfColors: number}}),
    getBackgroundColor
  }
}




const themesReducer = (state, action) => {

  switch (action.type){
    case 'add-theme': {
      return {
        ...state,
        themes: [action.payload.theme, ...state.themes]
      } 
    }

    case 'remove-theme': {
      return {
        ...state,
        themes: state.themes.filter((_val, idx) => action.payload.idx !== idx)
      }
    }

    case 'clear-themes': {
      return {
        ...state,
        themes: []
      }
    }

    case 'select-theme': {
      return {
        ...state,
        currentTheme: state.themes[action.payload.idx]
      }
    }

    case 'set-color': {
      const palette = [...state.currentTheme];
      palette[action.payload.idx] = action.payload.value;

      return {
        ...state,
        currentTheme: palette
      }
    }

    case 'set-number-of-colors': {
      const currentTheme = ['#aaa', '#bbb','#ccc','#ddd','#eee'];
      return {
        ...state,
        numberOfColors: action.payload.numberOfColors,
        currentTheme: currentTheme.splice(0, action.payload.numberOfColors)
      }
    }

    case 'set-background-strategy': {
      return {
        ...state,
        backgroundStrategy: action.payload.value
      }
    }

    case 'set-background-color-idx': {
      return {
        ...state,
        backgroundColorIdx: action.payload.value
      }
    }

    case 'reset-state': {
      return action.payload;
    }

    default: {
      console.error("Invalid action");
    }
  }
}




export {useThemes};

