import { useContext } from 'react';

import {Button,Menu, MenuItem, MenuDivider, Icon} from '@blueprintjs/core';
import { Popover2 } from "@blueprintjs/popover2";        

import { WabwebContext } from '../contexts/wabweb_context';
import { useAuth0 } from "@auth0/auth0-react";

import { isDesigner } from '../utils/auth0_utils';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

const UserButton = ({store, dialogTriggerProps}) => {
  const {t} = useTranslation();
  const { user, logout } = useAuth0();
  const {userAccount, dir} = useContext(WabwebContext);



  return (
    <>
    <Popover2
      interactionKind="click"
      placement="bottom"
      content={
        <>
        <Menu>
            
            {
              <Link to='/account' style={{textDecoration: 'none'}}>
                <MenuItem icon="user" text={user.email} />
              </Link>
            }
            {
              isDesigner(user) && 
                <MenuItem icon="star-empty" disabled text="Designer Account"/>
            }

            <MenuDivider title={t("general")}/>
            
            <Link to={'/projects'} style={{textDecoration: 'none'}} >
              <MenuItem 
                text={t('my_projects')} 
                icon="book" 
                intent="primary"/>
            </Link>

            <MenuDivider title={t("settings")}/>
            
            {
              !userAccount.isSubscribed && !isDesigner(user) && 
              <MenuItem 
                icon="crown" 
                text={t("subscribe_now")} 
                intent="warning" 
                {...dialogTriggerProps}></MenuItem>
            }
            
            {
              userAccount.isSubscribed &&
              <MenuItem icon="refresh" text="Manage Subscription">
                <MenuItem 
                  text="Update Card Information"
                  onClick={()=>window.open(userAccount.updateUrl)}/>
                <MenuItem 
                  text="Cancel Subscription"
                  onClick={()=>window.open(userAccount.cancelUrl)}/>
              </MenuItem>
            }
            
            
            <MenuDivider/>
            <MenuItem icon="log-out" text={t("logout")}
            onClick={() => logout({appState: {returnTo: `${window.location.origin}${window.location.search}` }})}/>
            
        </Menu>

        </>
      }
      >

    <Button      
      minimal>
        <Icon icon='user' style={{color: 'white'}}/>
      </Button>
    </Popover2>

    </>
  );
};

export {UserButton};