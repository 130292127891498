import {addGlobalFont} from 'polotno/config';


import fontFrutigerLT45 from './fonts/FrutigerLT45.ttf';
import fontFrutigerLT55 from './fonts/FrutigerLT55Roman.ttf';
import fontFrutigerLT65 from './fonts/FrutigerLT65Bold.ttf';
import fontFrutigerLT75 from './fonts/frutigerLT75black.ttf';
import fontNewSansArabic from './fonts/NewSansArabic.ttf';
import fontVipArabic from './fonts/VIPArabic.ttf';
import fontMjFaraz from './fonts/MjFaraz.ttf';

const globalFonts = [
  {
    name: 'Frutiger LT',
    styles: [
      {
        name: 'Regular 45',
        fontFamily: 'FrutigerLT45',
        src: fontFrutigerLT45
      },
      {
        name: 'Roman 55',
        fontFamily: 'FrutigerLT55',
        src: fontFrutigerLT55
      },
      {
        name: 'Bold 65',
        fontFamily: 'FrutigerLT65',
        src: fontFrutigerLT65
      },
      {
        name: 'Black 75',
        fontFamily: 'FrutigerLT75',
        src: fontFrutigerLT75
      },            
    ]
  },
  {
    name: 'New Sans Arabic',
    styles: [
      {
        name: 'New Sans Arabic',
        fontFamily: 'NewSansArabic',
        src: fontNewSansArabic
      }
    ]
  },
  {
    name: 'VIP Arabic',
    styles: [
      {
        name: 'VIP Arabic',
        fontFamily: 'VIPArabic',
        src: fontVipArabic
      }
    ]
  },
  {
    name: 'MJ Faraz',
    styles: [
      {
        name: 'MJ Faraz',
        fontFamily: 'MjFaraz',
        src: fontMjFaraz
      }
    ]
  }  
];


const setupFonts = () => {
  globalFonts.forEach((font) => {
    font.styles.forEach((style) => {
      const fnt = new FontFace(style.fontFamily, `url(${style.src}) format('truetype')`);
      fnt.load().then(function(loaded_face) {
        document.fonts.add(loaded_face);
        addGlobalFont({fontFamily: style.fontFamily});
      }).catch(function(error) {
        console.log(error);
      });
  
    })
  });
}





export {globalFonts, setupFonts};