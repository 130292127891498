import { Button } from "@blueprintjs/core";

import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const {t} = useTranslation();

  return(
    <Button 
      icon="user" 
      text={t("log_in")}
      intent="warning"
      onClick={() => loginWithRedirect({appState: {returnTo: `${window.location.origin}/${window.location.search}` }})} />
  )
}


  export {LoginButton};