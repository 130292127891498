import { useEffect } from "react"


const Page = ({title, component}) => {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      {component}
    </>
  )
}


export default Page;