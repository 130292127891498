import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';

const useDesignsApi = () => {

  const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();



  const getClient = async () => {
    const headers = {
      'Content-Type': 'application/json'
    }

    try{
      const accessToken = await getAccessTokenSilently();
      headers['Authorization'] = `Bearer ${accessToken}`;
    } catch (error){
      // do nothing
    }

    return axios.create({
      baseURL: process.env.REACT_APP_WABWEB_BACKEND_HOST,
      // timeout: 1000,
      headers: headers
    })
  }

  const getDesign = async ({designId, projectId, locale = 'ar'}) => {
    const client = await getClient();

    return client.get(`/api/designs/${designId}.json`, {
      params:{
        project_id: projectId,
        locale: locale
      }
    })
      .then((res) => res.data)
      .catch((error) => error)
  }


  return {getDesign}
}


export {
  useDesignsApi
}