import { useEffect, useState } from 'react';
import TextField from '../fields/text_field2';
import { Spinner } from '@blueprintjs/core';

let page = 1;

const unsplashApi = {
  get: (string, pageNum = 1) => (
    fetch('https://api.polotno.dev/api/get-unsplash?' + new URLSearchParams({
      query: string,
      page: page,
      per_page: 30,
      key: 'nFA5H9elEytDyPyvKL7T'      
    }))
      .then(response => response.json())
      .then(data => data)
      .catch(error => console.log(error))
  )
}




const Results = ({store, results}) => {
  const handler = (image) => {
    store.selectedElements[0].set({src: image})
    store.openSidePanel('none');
  };

  return(
    <div style={{columnCount: 2}}>
      {
        results?.map((image) => (
          <img 
            key={image.id} 
            src={image.urls.small} 
            onClick={() => handler(image.urls.regular)}
            style={{ width: '100%', borderRadius: '5px', marginBottom: '5px', cursor: 'pointer'}}/>
        ))
      }
    </div>    
  )
}





const Unsplash = ({store}) => {

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  
  const handler = (str = '') => {
    if(str.length <= 2) return null;
      
    unsplashApi.get(str).then((results) => {
      setResults(results.results);
      setLoading(false);
    })
  }

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      handler(searchTerm);
    }, 2000);

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [searchTerm])


  useEffect(()=>{
    handler('nature');
  }, [])

  return(
    <div>
      <TextField 
        name=""
        placeholder="Search for images... (English only)"
        handler ={setSearchTerm}
      />
      {loading && <Spinner size={20}/>}
      <div style={{textAlign: 'center', marginBottom: '10px'}}>Photos by <a href="https://unsplash.com">Unsplash</a></div>
      <Results store={store} results={results}/>
    </div>
  )
}


export default Unsplash;