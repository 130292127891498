export const localeEn = 
  {
    "Export": "Export",
    "Back to Designs": "Back to Designs",
    "Change Image": "Change Image",
    "Design Direction": "Design Direction",
    "Colors": "Colors",
    "Themes Colors": "Themes Colors",
    "Invert": "Invert",
    "Create Theme": "Create Theme",
    "Theme Colors": "Theme Colors",
    "Remove": "remove",
    "remaining_credits": "Your current Balance: {{creditNum}}.",
    "watermark_will_be_removed": "The watermark will be removed after purchase.",
    "rtl": "Right to Left",
    "ltr": "Left to Right",

    // subscribe now button
    "subscribe_now": "Subscribe Now",
    "recurring_subscriptions": "Subscriptions", 
    "one_time": "One Time Payment",
    "get_started": "Get Started",

    "log_in": "Log In",
    "download": "Download",

    "remove_watermark": "Remove Watermark",

    // qr 
    "generate_qr_TITLE": "QR Code",
    "generate_qr_BODY": "Generate QR code with any URL you want.",
    "generate_qr_PLACEHOLDER": "Enter URL (e.g. https://www.example.com)",
    "generate_qr_BUTTON_LABEL": "Add new QR code",


    "more_backgrounds": "More Backgrounds",

    "general": "General",
    "settings": "Settings",
    "my_projects": "My Projects",
    "logout": "Log out",
  }