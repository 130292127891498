import { useEffect, useState } from 'react';

const useInput = (defaultValue, handler = () => {}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(()=>{
    handler(value);
  }, [value]);

  return [{value, onChange: (e) => setValue(e.target.value)}]
}

export default useInput;