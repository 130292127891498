import Konva from 'konva';



const invertTheme = (store) => {  
  store.activePage?.children.forEach((el) => {

    // save current rotation
    const tempRotation = _normalized(el.rotation);

    // rotate to 0 around self
    rotateAroundCenter(el, 0);

    // move to other side
    invertX(el, store);

    // rotate 
    rotateAroundCenter(el, tempRotation * -1);
    

    if(el.type === 'text' && el.align !== 'center'){
      const align = el.align === 'left' ? 'right' : 'left'
      el.set({align: align})
    }
    if(el.type === 'svg' && el.name !== 'qr'){
      el.set({flipX: !el.flipX})
    }
  })
}




export default invertTheme;








const _normalized = (value) => { // value: rotation [-180, 180]
  if (value < 0)
    return value += 360.0;
  else
    return value;
}







const rotatePoint = ({ x, y }, rad) => {
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

// will work for shapes with top-left origin, like rectangle
const rotateAroundCenter = (node, rotation) => {
  //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
  const topLeft = { x: -node.width / 2, y: -node.height / 2 };


  const currentRotation = (node.rotation >= 0) ? node.rotation : (360 + (node.rotation));

  const current = rotatePoint(topLeft, Konva.getAngle(currentRotation));
  const rotated = rotatePoint(topLeft, Konva.getAngle(rotation));
  const dx = rotated.x - current.x,
    dy = rotated.y - current.y;

  node.set({x: (node.x + dx), y: (node.y + dy), rotation: rotation});

}



const invertX = (el, store) => {
  const xInv = store.width - el.x - el.width
  el.set({x: xInv})
}