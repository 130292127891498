import { Button, Icon } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import {Hidden} from "react-grid-system";


const BackToDesignsButton = () => {

  const {t} = useTranslation();

  return (
    <Button
      id="backToDesignsButton"
      minimal
      onClick={() => {
        window.location.href = (window.location.href !== document.referrer && document.referrer) || 'https://www.wabweb.com'
      }}
      intent={'success'}
      style={{color:'white', fontWeight: 'bold', fontSize: '12pt'}}
    >
      <Icon icon='undo'/> 
      <Hidden xs sm>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {t('Back to Designs')}
      </Hidden>
    </Button>
  )
}

export {BackToDesignsButton};