import { Button } from "@blueprintjs/core";


const PaletteButton = ({colors , onClick, minWidth='10em'}) => (
    <Button 
      onClick={onClick}
      small={true}
      style={{boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: '5px', border: '1px solid #ccc'}}>
        <div style={{overflow: 'hidden', borderRadius: '2px', display: 'flex', height: '35px',  minWidth: minWidth}}>
          {
            colors.map(color => 
              <div style={{flexGrow: 1, backgroundColor: `${color}`, minHeight: '30px'}} key={color}></div>    
              )
          }
        </div>    
      </Button>
)

export default PaletteButton;