import { InputGroup, ControlGroup } from '@blueprintjs/core';
import ColorField from '../fields/color_field';
import { SectionTab } from 'polotno/side-panel';
import FaShapes from '@meronex/icons/fa/FaShapes';
import { Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import PaletteButton from '../components/palette_button';

import { HTMLSelect } from '@blueprintjs/core';
import { useContext, useEffect, useReducer, useState } from 'react';
import { WabwebElement } from '../shared/wabweb_element';
import {ThemeContext} from '../contexts/theme_context';
import { useAuth0 } from "@auth0/auth0-react";

import { isDesigner } from '../utils/auth0_utils';
import colorStrategies from '../utils/wabweb_utils/color_strategies';


const tab = (props) => (
  <SectionTab name="Theme" {...props}>
    <FaShapes icon="new-text-box" />
  </SectionTab>
)


const Panel = ({store}) => {
  const {t} = useTranslation();
  const themeApi = useContext(ThemeContext);
  const { user } = useAuth0();


  const [elements, setElements] = useState(store.activePage.children);


  
  useEffect(()=>{
    const intervalId = setInterval(()=>{
      setElements(store.selectedElements);
    }, 200);

    return (() => {
      clearInterval(intervalId);
    });
  })

  return (
    <>
      <div style={{overflowY: 'scroll', height: '100%', display: 'flex', flexDirection: 'column', gap: '10px'}}>
          {
            // Colors in Theme
            (isDesigner(user)) &&
            <div>
              <b>Colors in Theme</b>
              <InputGroup 
                asyncControl = {true}
                value={themeApi.numberOfColors}
                onChange={(e) => {themeApi.setNumberOfColors(e.target.value)}}/>
            </div>
          }
          
          {
            // Color Palette
            <>
              <div>
                <h3>Current Theme</h3>
                
                <div style={{display: 'flex', minWidth: '150px', width: '150px', gap: '10px', marginBottom: '10px'}}>
                    {themeApi.currentTheme.map((color, index) => (
                        <ColorField 
                          key={index}
                          label=""
                          helperText=""
                          value={color}
                          handler={(value) => themeApi.setColor(value, index)}
                          onStart={() => {store.history.startTransaction()}}
                          onDone={() => {store.history.endTransaction()}}
                      />
                    ))}
                </div>
                <p style={{color: '#aaa'}}>Edit the entire design with theme colors</p>

                
                {
                  isDesigner(user) && 
                  <>
                    <br/>
                    <Button text={"Create Theme"} small onClick={themeApi.addTheme} />
                  </>
                }
              </div>            
            </>
          }

          

          {
            // Suggested Colors
            <>
            <h3>Suggested Themes</h3>
            {
              themeApi.themes.map( (theme, index) => (
                <div key={index}>
                  <PaletteButton colors={theme} onClick={() => themeApi.setCurrentTheme(index)} />
                  {
                    isDesigner(user) &&
                    <Button text={t('Remove')} minimal onClick={()=> themeApi.removeTheme(index)}/> 
                  }
                </div>
              ))
            }
            </>
          }



          {
            isDesigner(user) && <PageSettings themeApi={themeApi} store={store} />
          }


          {
            // Schema
            (isDesigner(user)) &&
            elements.slice().reverse().map(el => 
                <SchemaElement 
                  key={el.id} 
                  element={el} 
                  themeApi={themeApi} 
                  selectElement={() => store.selectElements([el.id])}/>
              )        
          }
      </div>
    </>
  )
}




const ThemeSection = {
  name: 'theme',
  Tab: tab,
  visibleInList: false,
  Panel: Panel
};




export default ThemeSection;  






const PageSettings = ({themeApi}) => {



  const backgroundColorIdx = {
    value: themeApi.backgroundColorIdx,
    options: themeApi.colorOptions,
    onChange: (e) => themeApi.setBackgroundColorIdx(e.target.value)    
  }

  return (
    <>    
    {
      <div style={{borderTop: '1px solid #ccc', marginTop: '40px'}}>      
        <b>Background Strategy</b>
        <div>
          <ControlGroup>
            <HTMLSelect 
              value={themeApi.backgroundStrategy}
              options= {colorStrategies}
              onChange={(e) => themeApi.setBackgroundStrategy(e.target.value)}
            />
            {
            themeApi.backgroundStrategy !== 'Disabled' && 
            
            <HTMLSelect 
              {...backgroundColorIdx}    
            />
            }
          </ControlGroup>
        </div>        
      </div>  
    }
    
    </>
  )
}







const SchemaElement = ({element, themeApi, selectElement}) => {
  const welement = new WabwebElement(element);

  const initialState = {
    fillStrategy: welement.fillStrategy(),
    fillColorIdx: welement.fillColorIdx(),
    borderStrategy: welement.borderStrategy(),
    borderColorIdx: welement.borderColorIdx()
  }


  const schemaElementReducer = (state, updates) => {
    return {
      ...state,
      ...updates
    }
  }

  const [state, setState] = useReducer(schemaElementReducer, initialState);


  useEffect(() => {
    if(JSON.stringify(state) === JSON.stringify(initialState)) return;
    
    welement.setValues({
      fillStrategy: state.fillStrategy, 
      fillColorIdx: state.fillColorIdx, 
      borderStrategy: state.borderStrategy, 
      borderColorIdx: state.borderColorIdx
    });

    themeApi.refreshElement(element);
  }, [state]);





  return(
    <div style={{display: 'flex', flexDirection: 'column', gap:'5px', marginBlock: '20px', paddingBottom: '20px', borderTop: '1px solid rgba(17,20,24,.15)'}}>

      {
        // Title 

        <h4>
          {element.name} ({element.type})
          <Button
            text={`${element.id}`}
            onClick={selectElement} 
            minimal={true}
            small={true}
            intent='primary'/>

        </h4>
      }

      {
        // Fill Strategy

        <>
          <b>Fill Strategy</b>
          <div>

          <ControlGroup>
              <HTMLSelect value={state.fillStrategy} options={themeApi.colorStrategies} onChange={(e) => setState({fillStrategy: e.target.value})}/>
            {state.fillStrategy !== "Disabled" && 
              <HTMLSelect value={state.fillColorIdx} options={themeApi.colorOptions} onChange={(e) => setState({fillColorIdx: e.target.value})} />}
          </ControlGroup>
          </div>
        </>
      }

      {
        // Border Strategy

        <>
            <b>Border Strategy</b>
            <div>
                <ControlGroup>
                  <HTMLSelect value={state.borderStrategy} options={themeApi.colorStrategies} onChange={(e) => setState({borderStrategy: e.target.value})}/>
                  {state.borderStrategy != "Disabled" && 
                    <HTMLSelect value={state.borderColorIdx} options={themeApi.colorOptions} onChange={(e) => setState({borderColorIdx: e.target.value})}/>}
                </ControlGroup>
            </div>
        </>
      }
    
    </div>
  )
}