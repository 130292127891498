import { Navbar, Spinner, Tabs, Tab, Alignment, Button } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useActivitiesApi } from "../lib/wabweb_api/activities";
import { useProjectsApi } from "../lib/wabweb_api/projects";

import { Container, Row, Col } from 'react-grid-system';

const MyProjects = () => {
  const [openTab, setOpenTab] = useState('by_date');

  const [projects, setProjects] = useState(null);

  const {getActivities, destroyActivity} = useActivitiesApi();


  const navigate = useNavigate();

  const handleTabChange = (value) => {
    setOpenTab(value);
  }

  const DestroyProjectLink = ({projectId}) => {
    const [executed, setExecuted] = useState(false);

    const handleClick = async () => {
      setExecuted(true);
      destroyActivity(projectId).then(() => {
        setProjects(projects.filter((obj) => obj['id'] !== projectId))
      })
    }
  
    if(executed) return <span style={{color: '#aaa'}}>Removing...</span>;

    return ( 
      <Button 
        small 
        minimal 
        style={{fontSize: '10pt', color: '#aaa'}}
        onClick={handleClick}>
          Remove
      </Button>
    )
  }



  useEffect(() => {
    (async () => {
      const data = await getActivities();
      
      if(data){
        setProjects(data.activities);
        console.log(data);
      } else {
        alert('did not work');
      }
    })();
  }, []);



  


  if(projects == null) {
    return (
      <>
        <Navbar style={{background: 'linear-gradient(90deg, hsla(235, 90%, 70%, 1) 0%, hsla(235, 50%, 50%, 1) 50%)'}}/>
        <div style={{marginTop: '20px', textAlign: 'center'}}>
          <Spinner size={30}/>
          {<>Fetching Projects...</>}
        </div>
      </>
    )
  }

  return (
    <div>
      <Navbar style={{background: 'linear-gradient(90deg, hsla(235, 90%, 70%, 1) 0%, hsla(235, 50%, 50%, 1) 50%)'}}>
        <Navbar.Group align={Alignment.LEFT}>
          <Button 
            minimal 
            style={{color:'white', fontWeight: 'bold', fontSize: '12pt', backgroundColor: 'rgba(255,255,255,0.1)'}}
            onClick={() => navigate(-1)}
          >
            Back to Editor
          </Button>
        </Navbar.Group>
      </Navbar>


      <Navbar style={{background: 'white'}}>
        <Navbar.Group>
          <Tabs onChange={handleTabChange}>
            <Tab id='by_date' style={{outline: 'none'}}>By Date</Tab>
            <Tab id='by_design' style={{outline: 'none'}}>By Design</Tab>
          </Tabs>
        </Navbar.Group>
      </Navbar>


        <div className="bg" style={{width: '100%', margin: '0 auto', marginTop: '20px'}}>
          

        {

            
            <div style={{marginTop: '20px'}}>
                {
                  openTab === 'by_date' ? 
                    <ByDate data={projects} DestroyProjectLink={DestroyProjectLink}/> :
                    <ByDesign data={projects} DestroyProjectLink={DestroyProjectLink}/>
                }  
            </div>          
        }

        </div>

    </div>
  )
}



const ByDate = ({data, DestroyProjectLink}) => {

  return (
    <Container >
      <Row>
        {
          data.map((project) => {
            return (
              <Col  xs={6} md={3} lg={2} key={project.id}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  
                  {
                    <div>
                      #{project.id}
                    </div>
                  }   
                  {
                    <div style={{width: '100%', aspectRatio: '1/1', backgroundColor: 'rgba(242,243,245,1)', borderRadius: '10px', overflow:'hidden'}}>
                      
                      <div style={{display: 'flex', alignItems: 'center', boxSizing: 'border-box', justifyContent: 'center', padding: '20px'}}>
                        <Link to={`/?d=${project.design_id}&p=${project.id}`} target="_blank">
                          <img
                            style={{
                              width: '100%',
                              border: '1px solid #d8dbde', borderRadius: '4px'}}
                            src={project.image}
                          />
                        </Link>                          
                      </div>

                    </div>
                  }
                  {
                    <div>
                      <DestroyProjectLink projectId={project.id}/>
                    </div>
                  }

                </div>

                  
              </Col>
            )
          })    
        }
        {
          (data.length%2 !== 0) && 
          <div></div>
        }
      </Row>
    </Container>
  )}


const ByDesign = ({data, DestroyProjectLink}) => {
  const grouped = data.reduce((result, item) => {
    result[item.design_id] ||= [];
    result[item.design_id].push(item);
    return result;
  }, {});

  const groupKeys = Object.keys(grouped)
  
  return (
    groupKeys.map((key) => {
      return(
        <div key={key}>
          <div>{key.replace(/(.{7})..+/, "$1…")}</div>
          <ByDate data={grouped[key]} key={key} DestroyProjectLink={DestroyProjectLink} />
        </div>
      )
    })
  )
}



export default MyProjects;

