

const addWatermark = ({store}) => {
  let watermarkLocations = [];
  const n = 10
  for( let i=0; i < n; i++ ){
    const x = Math.floor(Math.random() * store.width);
    const y = Math.floor(store.height/n) * (i+1);
    const a = Math.random() * 0.1
    watermarkLocations.push([x,y, a]);
  }



  let item = {
    name: '_watermark',
    type: 'text',
    text: null,
    
    x: 0,
    y: store.height/2,
    fontSize: store.width/10,
    fontWeight: 'bolder',
    width: store.width,
    align: 'center',
    fontFamily: 'Arial',
    draggable: false,
    alwaysOnTop: true,
    contentEditable: false,
    fill: 'rgba(0,0,0,0.2)',
    strokeWidth: 4,
    stroke: 'rgba(255,255,255,0.2)',
    styleEditable: false,
    selectable: false
  }
              

  const watermarkText1 = 'wabweb.com';
  

  removeWatermarks(store);

  store.pages.forEach((page) => {
    page.addElement({...item, text: watermarkText1});
  })
}


const removeWatermarks = (store) => {
  const elems = store.pages.map((page) => page.children.filter((el => el.name === '_watermark'))).flat();
  store.deleteElements(elems.map((el) => el.id));
}



export { addWatermark, removeWatermarks };


