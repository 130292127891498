import { useState, useRef } from 'react';
import QRCode from 'qrcode';
import * as svg from 'polotno/utils/svg';
import { Button, InputGroup, Dialog } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

// create svg image for QR code for input text
async function getQR(text) {
  return new Promise((resolve) => {
    QRCode.toString(
      text || 'no-data',
      {
        type: 'svg',
        color: {
          dark: '#000', // Blue dots
          light: '#fff', // Transparent background
        },
      },
      (err, string) => {
        resolve(svg.svgToURL(string));
      }
    );
  });
}

// define the new custom section ...
const useQrDialog = ({store, element = null}) => {
  const {t} = useTranslation();
  
  const inputRef = useRef();
  
  const [isOpen, setIsOpen] = useState(false); 


  const handler = async () => {
    const src = await getQR(inputRef.current.value);

    if (element){
      element.set({src: src})
    } else {
      store.activePage.addElement({
        type: 'svg',
        name: 'qr',
        x: 50,
        y: 50,
        width: 200,
        height: 200,
        src,
        resizable: true
      });
    }

    setIsOpen(false);
  }


  const QrDialog = () => (<Dialog 
      isOpen={isOpen} 
      canOutsideClickClose 
      canEscapeKeyClose 
      onClose={() => setIsOpen(false)}>
      
      <div style={{padding: '1em'}} dir='auto'>
        <h3 style={{ marginBottom: '10px', marginTop: '5px' }}>{t("generate_qr_TITLE")}</h3>
        <p>{t("generate_qr_BODY")}</p>
        <InputGroup
          placeholder={t("generate_qr_PLACEHOLDER")}
          style={{ width: '100%', marginTop: '10px', marginBottom: '10px', direction: 'ltr' }}
          inputRef={inputRef}
        />
        
        <Button
          onClick={handler}
          fill
          intent="primary"
        >
          {t("generate_qr_BUTTON_LABEL")}
        </Button>
      </div>
    </Dialog>);


  return ({
    openQrDialog: () => setIsOpen(true),
    QrDialog
  })
}

export {
  useQrDialog
}