import ReactDOM from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";


import './index.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

// import App from './customer_app/customer_app';


import { createBrowserHistory } from 'history';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import WabwebEditor from './pages/wabweb_editor';
import MyProjects from './pages/my_projects';
import { PrivateRoute } from './components/private_route';
import MyAccount from './pages/my_account';

import Page from './pages/page';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Page component={<WabwebEditor/>} title={'Editor | Wabweb'}/>
  },
  {
    path: '/projects',
    element: <Page component={<PrivateRoute component={<MyProjects/>}/>} title={'Projects | Wabweb'} />
  },
  {
    path: '/account',
    element: <Page component={<PrivateRoute component={<MyAccount/>}/>} title={'Account | Wabweb'} />
  }
])


const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  if(appState && appState.returnTo){
    history.push(appState.returnTo);
    window.location.reload();
  }
};


const providerConfig = {
  domain: "dev-all.eu.auth0.com",
  audience: "wabweb_backend",
  // scope: 'openid email profile',
  clientId: "uc2FNIdjSxJ31W16aHH4XEygH3shxDtS",
  redirectUri: window.location.origin,
  onRedirectCallback,
};


// add lang attribute to html tag

const 
  element = document.getElementsByTagName("html")[0],
  searchParams = new URLSearchParams(window.location.search),
  lang = searchParams.get('lang') || 'en'

element.setAttribute('lang',searchParams.get('lang'))




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <Auth0Provider
        {...providerConfig}
      >
        <RouterProvider router={router}/>
      </Auth0Provider>
);



