class WabwebElement{

  constructor(element){
    this.element = element
  }

  getValues(){

    return {
      fillStrategy: 'Disabled',
      fillColorIdx: '0',
      borderStrategy: 'Disabled',
      borderColorIdx: '0',
      locales: {},
      ...this.element.custom
    }
  }

  setValues(values = {}){
    const custom = this.getValues();
    this.element.set({custom: {...custom, ...values}})
  }


  setLocale(locale, value){
    const locales = {...this.getValue('locales')};
    locales[locale] = value;

    this.setValues({locales: locales});
  }


  applyLocale(locale){
    const rtls = ['ar', 'he', 'fa', 'ur'];
    const postfix = rtls.includes(locale) ? "\u200F" : "";
  
    if(!this.getLocale(locale)) return;
    this.element.set({text: this.getLocale(locale) + postfix, fontFamily: this.element.fontFamily});
  }


  locales(){
    return this.getValue('locales');
  }

  getLocale(locale){
    return this.locales()[locale] || '';
  }

  fillStrategy(){
    return this.getValue('fillStrategy');
  }

  fillColorIdx(){
    return parseInt(this.getValue('fillColorIdx'));
  }

  borderStrategy(){
    return this.getValue('borderStrategy');
  }

  borderColorIdx(){
    return parseInt(this.getValue('borderColorIdx'));
  }



  getValue(key){
    return this.getValues()[key]
  }

}


export {WabwebElement};