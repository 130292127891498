const useLocalStorage = (key) => {

  const getItem = () => {
    return JSON.parse(localStorage.getItem(key));
  }

  const setItem = (item) => {
    localStorage.setItem(key, JSON.stringify(item));
  }

  const resetItem = () => {
    localStorage.setItem(key, null);
  }

  return {getItem, setItem, resetItem};
}

export {useLocalStorage};

