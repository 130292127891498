import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';


import FaQrcode from '@meronex/icons/fa/FaQrcode';
import { useQrDialog } from './use_qr_dialog';
import colorOnBg from '../shared/on_color';


const AddButton = ({store}) => {

  const {t} = useTranslation();
  
  const {openQrDialog, QrDialog} = useQrDialog({store});

  const addText = () => {
    const el = store.activePage.children.find((el) => el.type === 'text');

      store.activePage.addElement({
        type: 'text',
        x: 50, y: 50,
        text: 'New Text',
        fill: colorOnBg(store.activePage.background) || "rgba(0,0,0,0)",
        fontSize: 50,
        width: store.width / 10,
        fontFamily: el?.fontFamily,
        resizable: true
      })
    }

    const addImage = () => {
      store.openSidePanel('upload');
    }

    const addElement = () => {
      store.openSidePanel('elements');
    }
  

  return(
    <>
      <QrDialog />

      <Popover2
        interactionKind="click"
        placement="bottom"
        content={
          <>
          <Menu>
              <MenuItem text={t("text")} icon="new-text-box" onClick={addText} />
              <MenuItem text={t("image")} icon="media" onClick={addImage}/>
              <MenuItem text={t("shape")} icon="star-empty" onClick={addElement} />
              <MenuItem 
                text={<span className='bidi'>{t("qr_code")}</span>} 
                icon={<FaQrcode/>} onClick={openQrDialog} />
          </Menu>
          </>
        }
      >
        <Button      
          icon="plus" 
          minimal/>
      </Popover2>   
    </> 
  )  
}


export {AddButton};