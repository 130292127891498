import React, { useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import {useTranslation} from 'react-i18next';


const UploadButton = ({store, element, elements}) => {
  const {t} = useTranslation();
  const handler = () => {store.openSidePanel('wabweb-upload')}


  useEffect(() => {
    return (() => store.openSidePanel('none'));
  }, []);

  return(
    <Button
      onClick={handler}
      className='button'
      intent='primary'
      outlined={true}
      icon='media'
      text={t('Change')}
    />
  )
}


export default UploadButton;