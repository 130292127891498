import { Alignment, Button, Divider, HTMLTable, Navbar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react"
import { Container, Row, Col } from 'react-grid-system';



const MyAccount = () => {

  const navigate = useNavigate();

  return (
    <>
      <Navbar style={{background: 'linear-gradient(90deg, hsla(235, 90%, 70%, 1) 0%, hsla(235, 50%, 50%, 1) 50%)'}}>
        <Navbar.Group align={Alignment.LEFT}>
          <Button  
            minimal 
            style={{color:'white', fontWeight: 'bold', fontSize: '12pt', backgroundColor: 'rgba(255,255,255,0.1)'}}
            onClick={() => navigate(-1)}
          >
            Back to Editor
          </Button>
        </Navbar.Group>
      </Navbar>

      <Container>
        <Row>
          <Col>
            <h2>Email</h2>
            <h1>khaled@wabweb.com</h1>
            <Divider/>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Plan</h2>
            <h1>Monthly Subscription</h1>
            <Button minimal intent={'primary'}><b>Edit</b></Button>
            <Button minimal intent={'primary'}><b>Cancel</b></Button>
            <Divider/>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Payments</h2>
            <HTMLTable style={{width: '100%'}}>
              <thead>
                <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Amount</th>
                </tr>
              </thead>
            </HTMLTable>
          </Col>
        </Row>            

      </Container>
    </>
  )
}


export default MyAccount;