import { observer } from 'mobx-react-lite';

import { Toolbar } from 'polotno/toolbar/toolbar';


import {ImageRemoveBackground} from './background-remover';
import ChangeImageButton from './change_image_button';
import FontButton from './font_button';
import { AlignmentButton } from './alignment_button';
import { TrashButton } from './trash_button';
import { QrButton } from './qr_button';
import { StyleButton } from './style_button';
import { BackgroundColorButton } from './background_color_button';
import { AddButton } from './add_button';




const CustomToolbar = observer(({store}) => {


  return(
    <Toolbar store={store} 
      components={{
        ActionControls,
        TextAlignment: AlignmentButton, 
        TextFontFamily: FontButton,
        TextTrash: TrashButton,
        ImageChange: ChangeImageButton, 
        // ImageRemoveBackground: ImageRemoveBackground,
        ImageTrash: TrashButton,
        SvgTrash: TrashButton,
        SvgQr: QrButton,
        Group: ()=>{},
        Lock: ()=>{},
        Duplicate: ()=>{},
        Opacity: ()=>{},
        Remove: () => {},
        TextSpacing: () => {},
        ImageFitToBackground: ()=>{}}}/>
  )}
)



const ActionControls = observer(({ store }) => {
  return(
    <div style={{display: 'flex', gap: '10px'}} >
      <AddButton store={store}/>
      {
        store.selectedElements.length === 0 &&
        <>
          <StyleButton store={store}/>
          <BackgroundColorButton store={store}/>
        </>
      }
    </div>
  )
});





export default CustomToolbar;