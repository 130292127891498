import { SketchPicker } from 'react-color';

import { Button } from '@blueprintjs/core';

import { Popover2 } from "@blueprintjs/popover2";

import { useState } from 'react';
import colorOnBg from '../shared/on_color';


const ColorField = ({label, handler, value, helperText, onStart, onDone, icon='', children}) => {

  const [initial, setInitial] = useState(true);


  const handleChange = (color) => {
    if(initial){
      setInitial(false);
      onStart();
    }

    handler(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`);
  }

  const onChangeComplete = () =>{
    setInitial(true);
    onDone();
  }





  return(
      <Popover2
        interactionKind="click"
        placement="bottom"
        content={
          <>
          <SketchPicker
          color={value}
          onChange={handleChange}
          onChangeComplete={onChangeComplete}
          />
          {children}
        </>
        }
        renderTarget={({ isOpen, ref, ...targetProps }) => (
          // <div style={{width: "30px", height: "30px", borderRadius: '2px', background: value}}></div>
          <Button 
          {...targetProps} 
          icon={icon}
          elementRef={ref} 
          small={true}
          intent="primary" 
          style={{
            color: colorOnBg(value), 
            minWidth: "30px", 
            minHeight: '30px', 
            background: isValidHttpUrl(value) ? `url(${value}) center / cover` : value
          }}/>
        )}
      />
  )
}



const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}




export default ColorField;
