import { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { Dialog, Divider } from "@blueprintjs/core";

import { Button, Card, Elevation } from "@blueprintjs/core";

import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
 
import { WabwebContext } from "../contexts/wabweb_context";


const useSubscribeDialog = () => {
  const {user} = useAuth0();
  
  const [isOpen, setIsOpen] = useState(false)

  const {subscriptionPlans} = useContext(WabwebContext);
  const recurringPlans = subscriptionPlans.filter((plan) => plan.planType === 'subscription');
  const oneTimePlans = subscriptionPlans.filter((plan) => plan.planType === 'product');


  const onPlanSelect = ({planId}) => {
    const lang = document.getElementsByTagName("html")[0].getAttribute('lang');

    window.Paddle.Checkout.open({
      product: planId,       // Replace with your Product or Plan ID
      email: user.email,
      disableLogout: true,
      locale: lang,
      message: 'where is this?',
      customData: {"sub": user.sub},
      
    });    
  }

  const SubscribeDialog = () => {
    const {t} = useTranslation();

    return(
      <Dialog isOpen={isOpen} 
        canOutsideClickClose={true} 
        canEscapeKeyClose={true} 
        onClose={() => setIsOpen(false)}
        style={{minWidth: '50%'}}
        className='subscribe-dialog'>

        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginBlock: '20px'}}>
          <h2 style={{textAlign: 'center', color: '#1d1d1d'}}> {t("recurring_subscriptions")} </h2>

          {
            recurringPlans.map((plan) => (
              <SubscriptionPlan {...plan} onPlanSelect={() => onPlanSelect({planId: plan.planId})} key={plan.planId}/>
            ))
          }
        
          <Divider/>

          <h2 style={{textAlign: 'center', color: '#1d1d1d'}}> {t("one_time")} </h2>

          {
            oneTimePlans.map((plan) => <SubscriptionPlan {...plan} onPlanSelect={() => onPlanSelect({planId: plan.planId})} key={plan.planId}/>)
          }
                                          
        </div>
      </Dialog>
    )
  }

  return {
    SubscribeDialog, 
    dialogTriggerProps: {
      onClick: () => setIsOpen(true)
    }};
}



const SubscriptionPlan = ({name, description, planId, price, onPlanSelect}) => {

  const {isAuthenticated, loginWithRedirect} = useAuth0();
  const {t} = useTranslation();

  return(
    <>
      <Card interactive={true} elevation={Elevation.TWO} 
        style={{marginInline: '30px'}}>
          <span style={{fontSize: '14pt', fontWeight: 'bold', color: 'hsla(235, 89%, 60%, 1)'}}>{name}</span>
          <p>{description}</p>
          
          
          <div style={{fontSize: '14pt', fontWeight: 'bolder', marginBottom: '10px', direction:'ltr'}}>
            <Price planId={planId}/>
            </div>
          {
           !isAuthenticated &&
            <Button 
              minimal 
              outlined 
              style={{border: '2px solid black', fontWeight: 'bold', minWidth: '150px'}}
              onClick={() => loginWithRedirect({appState: {returnTo: `${window.location.origin}/${window.location.search}` }})}
            >
              {t('get_started')}
            </Button>
          }

          {
           isAuthenticated &&
            <Button 
              minimal 
              outlined
              intent={'success'} 
              style={{borderWidth: '2px', fontWeight: 'bold', minWidth: '150px'}}
              onClick={() => onPlanSelect(planId)}
            >
              Subscribe Now
            </Button>
          }          
      </Card>
    </>
  )
}

const Price = ({planId}) => {

  const [price, setPrice] = useState(undefined);

  useEffect(() => {
    window.Paddle.Product.Price('gross', planId, 1, (price) => {
      setPrice(price);
    }, []);
  })

  return (
    <span className={classNames({"bp4-skeleton": price === undefined})}>
      {price}
    </span>
  )
}





export {useSubscribeDialog};